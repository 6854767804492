export const vehicleCountCheck = ({loadingFlag}) => ({
  type: `LOADING_COMPLETE`,
  payload: {
    loadingFlag,
  },
})

export const setVins = ({authorizedVinsList, garageVinList}) => ({
  type: 'VINS',
  payload: {
    authorizedVinsList,
    garageVinList,
  },
})
