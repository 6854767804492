import {APP_CONSTANT} from './Constants'
import i18n from 'i18next'

export function useBrandedTranslation(translationKey) {
  return i18n.t(`${translationKey}_${APP_CONSTANT.CONFIG.BRAND_CODE}`)
}

export function getBrandedTranslation(translationKey) {
  return i18n.t(`${translationKey}_${APP_CONSTANT.CONFIG.BRAND_CODE}`)
}

export function getBrandedKey(translationKey) {
  return `${translationKey}_${APP_CONSTANT.CONFIG.BRAND_CODE}`
}

export function getCountryKeyForGoogleConsent(translationKey) {
  if (APP_CONSTANT.CONFIG.COUNTRY_CODE === 'CAN') {
    return `${translationKey}_${APP_CONSTANT.CONFIG.COUNTRY_CODE}`
  }
  return `${translationKey}`
}
