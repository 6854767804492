import React from 'react'
import {analytics} from '../helperComponents/Analytics/Analytics'
export const EmbeddedLink = (props) => {
  function handleOnClick() {
    if (props.screenName && props.linkName) analytics.fireLinkTappedEvent(props.screenName, props.linkName)
  }

  return (
    <a href={props.to} rel="noreferrer" target="_blank" onClick={handleOnClick} data-testid="embeded-link">
      {props.children}
    </a>
  )
}
