import React from 'react'
import Dialog from './Dialog'
import {Trans, useTranslation} from 'react-i18next'
import Accordion from '../components/Accordion'
import {getBrandedKey, useBrandedTranslation} from '../helperComponents/contentUtils'
import {FEATURE_UI_NAME} from '../helperComponents/Constants'
import {EmbeddedLink} from '../components/EmbeddedLink'
import {AMPLITUDE_SCREEN_NAME} from '../helperComponents/Analytics/AnalyticsConstants'

const learnMoreBrandList = [
  FEATURE_UI_NAME.californiaAirResourcesBoard,
  FEATURE_UI_NAME.vehicleHealthToDealers,
  FEATURE_UI_NAME.preferredDealer,
]
const accordionBrandList = [FEATURE_UI_NAME.vehicleHealthToDealers]
const learnMorePara2List = [FEATURE_UI_NAME.californiaAirResourcesBoard]
const learnMultiParaList = [FEATURE_UI_NAME.amazonAlexa]
const learnMoreSubTitleList = []
export const LEARN_MORE_DIAGLOG_TYPE = Object.freeze({
  ACCORDION: 'accordion',
  LINK: 'link',
  NONE: 'none'
})

export default function LearnMoreDialog(props) {
  const {t} = useTranslation()

  function renderContent() {
    if (props.type === LEARN_MORE_DIAGLOG_TYPE.ACCORDION) return renderAccordionContent()
    return renderNonAccordianContent()
  }

  function getAccoridonText(category) {
    if (accordionBrandList.includes(props.featureName)) {
      return useBrandedTranslation(`lbl_${props.featureName}_${category}_accordion_content`)
    }
    return t(`lbl_${props.featureName}_${category}_accordion_content`)
  }

  function renderAccordionContent() {
    if (props.learnMoreCategories) {
      const data = props.learnMoreCategories.map((category) => {
        return {
          title: t(`lbl_${category}_accordion_title`),
          content: getAccoridonText(category),
        }
      })
      return (
        <Accordion data={data} className="learn-more-dialog__accordion" featureName={props.featureName} />
      )
    }
  }

  function renderNonAccordianContent() {
    if (props.featureName !== FEATURE_UI_NAME.preferredDealer)
    return (
      <a className="learn-more-dialog__link" href={t('lbl_learn_more_link_url')}>
        {t('lbl_learn_more_link_label')}
      </a>
    )
  }

  function getLearnMorePara(para) {
    if (learnMoreBrandList.includes(props.featureName)) {
      return getBrandedKey(`lbl_${props.featureName}_learn_more_${para}`)
    }
    return `lbl_${props.featureName}_learn_more_${para}`
  }

  const para1 = getLearnMorePara('para1')
  const para2 = getLearnMorePara('para2')
  const para3 = getLearnMorePara('para3')
  const para4 = getLearnMorePara('para4')
  const paragraphs = t(para1).split('*')
  return (
    <Dialog
      className="learn-more-dialog"
      open={props.open}
      title={t('lbl_learn_more')}
      subtitle={t(`lbl_${props.featureName}`)}
      primaryButtonLabel={t('lbl_go_back')}
      onPrimaryButtonCliked={props.onPrimaryButtonCliked}
      onCloseButtonCliked={props.onCloseButtonCliked}
    >
      {!learnMoreSubTitleList.includes(props.featureName) && (
        <span className="learn-more-dialog__section-title">{t('lbl_learn_more_section_title')}</span>
      )}
      {paragraphs.map((paragraph, index) => (
        <p key={`learn-more-dialog-p-${index}`} className="learn-more-dialog__paragraph">
          <Trans
            components={
              props.contentEmbedLink && {
                [props.contentEmbedLink.varKey]: (
                  <EmbeddedLink
                    to={t(props.contentEmbedLink.contentKey)}
                    linkName={props.contentEmbedLink.linkName}
                    screenName={AMPLITUDE_SCREEN_NAME.LEARN_MORE}
                  />
                ),
              }
            }
          >
            {paragraph}
          </Trans>
        </p>
      ))}
      {learnMorePara2List.includes(props.featureName) && (
        <>
          <p className="learn-more-dialog__paragraph">
            <Trans
              i18nKey={para2}
              components={{
                deliverLink: <EmbeddedLink to={'https://ford.com/'} />,
              }}
            />
          </p>
        </>
      )}
      {learnMultiParaList.includes(props.featureName) && (
        <>
          <p className="learn-more-dialog__paragraph">
            <Trans i18nKey={para2} components={{deliverLink: <EmbeddedLink to={'https://ford.com/'} />}} />
          </p>
          <p className="learn-more-dialog__paragraph">{t(para3)}</p>
          <p className="learn-more-dialog__paragraph">{t(para4)}</p>
        </>
      )}

      {renderContent()}
    </Dialog>
  )
}
