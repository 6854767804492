import React from 'react'

export default function Spinner() {
  return (
    <div data-testid="spinner" className="loading">
      <div className="spinner">
        <svg className="spinner__svg" viewBox="22 22 44 44">
          <circle
            className="spinner__svg__circle"
            cx="44"
            cy="44"
            r="20.2"
            fill="none"
            strokeWidth="3.6"
            strokeLinecap="round"
          ></circle>
        </svg>
      </div>
    </div>
  )
}
