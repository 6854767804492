export const loadingMiddleware = (store) => (next) => (action) => {
  next(action)
  switch (action.type) {
    case '[SPINNER] START':
      store.dispatch({type: `SPINNER WEB FLAG`, payload: true})
      break

    case '[SPINNER] STOP':
      store.dispatch({type: `SPINNER WEB FLAG`, payload: false})
      break
  }
}
