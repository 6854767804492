import {useTranslation} from 'react-i18next'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {analytics} from '../helperComponents/Analytics/Analytics'
import {AMPLITUDE_SCREEN_NAME, AMPLITUDE_SECTION_NAME} from '../helperComponents/Analytics/AnalyticsConstants'
import {APP_CONSTANT} from '../helperComponents/Constants'

export default function ManageDataWithThirdPartiesTile() {
  const {t} = useTranslation()
  const navigate = useNavigate()

  function handlePreferencesClick() {
    analytics.fireSetPreferencesTappedEvent(
      AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE,
      AMPLITUDE_SECTION_NAME.MANAGE_DATA_WITH_THIRD_PARTIES,
    )

    navigate('/manage-data-with-third-parties')
  }

  function getManageTPPolicyInfo() {
    if (APP_CONSTANT.CONFIG.COUNTRY_CODE === 'USA') {
      return <div className="manage-data-tile__content__desc">{t('lbl_policy_info_third_parties_USA')}</div>
    }
    return <div className="manage-data-tile__content__desc">{t('lbl_policy_info_third_parties')}</div>
  }
  const ariaLabelSetPreferences = t('lbl_manage_data_third_parties') + t('lbl_set_preferences')

  return (
    <div className="manage-data-tile" data-testid="manage-data-with-thrid-parties-tile">
      <div className="manage-data-tile__image manage-data-tile__image--third-party" />
      <div className="manage-data-tile__content">
        <div className="manage-data-tile__content__header">{t('lbl_manage_data_third_parties')}</div>
        {getManageTPPolicyInfo()}
        <div className="manage-data-tile__content__button-wrapper">
          <button
            aria-label={ariaLabelSetPreferences}
            className="fmc-button fmc-button-addon"
            onClick={handlePreferencesClick}
          >
            <span>{t('lbl_set_preferences')}</span>
          </button>
        </div>
      </div>
    </div>
  )
}
