import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import App from './App'
import {store} from './redux/Store'
import './i18n'
import {Helmet} from 'react-helmet'

const rootElement = document.getElementById('app')
const root = createRoot(rootElement)

root.render(
  <Provider store={store}>
    <Helmet>
      <meta
        name="Cross-Origin-Opener-Policy"
        content="same-origin" // This is the recommended setting for security
      />
    </Helmet>
    <App />
  </Provider>,
)
