import i18n from 'i18next'
import I18NextHttpBackend from 'i18next-http-backend'
import {initReactI18next} from 'react-i18next'
import {LANG_CONSTANT} from './components/helperComponents/Constants'

const parseMissingKeyHandler = (missingKey) => {
  return LANG_CONSTANT[missingKey] || missingKey
}

i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: '../assets/locales/{{lng}}.json',
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',
    parseMissingKeyHandler,
  })

export default i18n
