import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {utils} from '../helperComponents/Utils'

export default function SelectVehicle(props) {
  const {t} = useTranslation()
  const [activeVin, setActiveVin] = useState(props.defaultVehicle)
  const isDialogOpen = useSelector((state) => state.isDialogOpen)
  useEffect(() => {
    props.onInit(activeVin)
  }, [])

  const handleSelection = (e) => {
    const selectedVin = e.target.value
    setActiveVin(selectedVin)
    props.onSelect(selectedVin)
  }

  const generateOptions = (vehicleData) => {
    return vehicleData.map((vehicle, index) => (
      <option key={index} value={vehicle.vin}>
        {vehicle.vehicleName}
      </option>
    ))
  }

  const renderSelector = (vehicleData) => {
    return (
      <div>
        <div className={'select-vehicle__label'}>{t('lbl_select_vehicle')}</div>
        <select
          aria-hidden={isDialogOpen}
          tabIndex={utils.getTabIndex(isDialogOpen)}
          name="vehicles"
          id="vehicle-select"
          className="fmc-select select-vehicle__select"
          onChange={handleSelection}
          value={activeVin}
        >
          {generateOptions(vehicleData)}
        </select>
      </div>
    )
  }

  const renderReadOnlyInput = (vehicleData) => {
    return (
      <div className="fmc-floating-label select-vehicle__floating-label">
        <input
          id="select-vehicle-readonly-input"
          type="text"
          className="fmc-input select-vehicle__input"
          placeholder="true"
          value={vehicleData.vehicleName}
          tabIndex={'-1'}
        />
        <label htmlFor="select-vehicle-readonly-input" className="select-vehicle__floating-label__label">
          <span className="fmc-floating-label__text">{t('lbl_vehicle')}</span>
        </label>
      </div>
    )
  }

  const renderVehicleSelectorOrReadOnlyInput = () => {
    const orderedVehicleData = props.vehicleData
    if (props.vehicleData.length === 1) {
      return renderReadOnlyInput(orderedVehicleData[0])
    }
    return renderSelector(orderedVehicleData)
  }

  return <div className={'select-vehicle'}>{renderVehicleSelectorOrReadOnlyInput()}</div>
}
