import {applyMiddleware, combineReducers, createStore} from 'redux'
import {initialSetupMiddleware} from './middlewares/feature/InitialSetupMiddleware'
import {spinnerWebFlagReducer} from './reducers/SpinnerReducer'
import authenticationReducer from './reducers/AuthenticationReducer'
import {tokenMiddleware} from './middlewares/feature/TokenMiddleware'
import {fmaMiddleware} from './middlewares/core/FmaMiddleware'
import {loadingReducer} from './reducers/LoadingReducer'
import {loadingMiddleware} from './middlewares/core/LoadingMiddleware'
import {apiMiddleware} from './middlewares/core/ApiMiddleware'
import {experienceLayerMiddleware} from './middlewares/feature/ExperienceLayerMiddleware'
import {consentNormalizerMiddleware} from './middlewares/feature/ConsentNormalizerMiddleware'
import {consentsReducer} from './reducers/ConsentsReducer'
import {pullToRefreshReducer} from './reducers/PullToRefreshReducer'
import {selectedConsentReducer} from './reducers/SelectedConsentReducer'
import {saveConsentsMiddleware} from './middlewares/feature/SaveConsentMiddleware'
import {errorReducer, internetErrorReducer} from './reducers/ErrorReducer'
import {
  manageDataWithFordReducer,
  manageDataWithThirdPartiesReducer,
  manageUserDataWithThirdPartiesReducer,
  mobileAppCookieReducer,
  selectedVehicleDataReducer,
  selectedVinReducer,
  UnlinkThirdPartyAccountsReducer,
} from './reducers/TabsReducer'
import {
  authorizedUsersReducer,
  loggedInUserGuidReducer,
  loggedInUserStateReducer,
} from './reducers/AuthorizedUsersReducer'
import {privaciesReducer, privacyEnableReducer} from './reducers/PrivaciesReducer'
import {privacyNormalizerMiddleware} from './middlewares/feature/PrivacyNormalizerMiddleware'
import {savePrivaciesMiddleware} from './middlewares/feature/SavePrivacyMiddleware'
import {pageLoadReducer} from './reducers/PageLoadReducer'
import {callAlexaRemoteLoginMiddleware} from './middlewares/feature/CallAlexaRemoteLoginMiddleware'
import {vinReducer} from './reducers/VehicleReducer'
import {UIReducer} from './reducers/UIReducer'

// const reduxLogger = require('redux-logger')
// const logger = reduxLogger.createLogger()

const featureMiddleware = [
  initialSetupMiddleware,
  tokenMiddleware,
  experienceLayerMiddleware,
  consentNormalizerMiddleware,
  privacyNormalizerMiddleware,
  saveConsentsMiddleware,
  savePrivaciesMiddleware,
  callAlexaRemoteLoginMiddleware,
]

const coreMiddleware = [fmaMiddleware, loadingMiddleware, apiMiddleware]

const createStoreWithMiddleware = applyMiddleware(
  ...featureMiddleware,
  ...coreMiddleware,
  // logger,
)(createStore)

const rootReducer = combineReducers({
  spinnerWebFlag: spinnerWebFlagReducer,
  isAuthenticated: authenticationReducer,
  vins: vinReducer,
  loading: loadingReducer,
  consentData: consentsReducer,
  pullToRefreshFlag: pullToRefreshReducer,
  errorFlag: errorReducer,
  isConnected: internetErrorReducer,
  //Manage Data with Ford
  manageDataWithFord: manageDataWithFordReducer,
  //Manage Data with Third Parties
  manageDataWithThirdParties: manageDataWithThirdPartiesReducer,
  //Manage User Data with Third Parties
  manageUserDataWithThirdParties: manageUserDataWithThirdPartiesReducer,
  mobileAppCookies: mobileAppCookieReducer,
  unlinkThirdPartyAccounts: UnlinkThirdPartyAccountsReducer,
  // Authorized Vins List
  authorizedUsersForVin: authorizedUsersReducer,
  privacies: privaciesReducer,
  isPrivacyEnabled: privacyEnableReducer,
  userGuid: loggedInUserGuidReducer,
  userState: loggedInUserStateReducer,
  selectedConsent: selectedConsentReducer,
  selectedVin: selectedVinReducer,
  selectedVehicleData: selectedVehicleDataReducer,
  // Utility
  activePage: pageLoadReducer,
  isDialogOpen: UIReducer,
})

export const store = createStoreWithMiddleware(rootReducer)
