export const authorizedUsersReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET AUTHORIZED USERS':
      return action.payload.authorizedUsersForVin
  }

  return state
}

export const loggedInUserGuidReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET USER GUID':
      return action.payload.userGuid
  }

  return state
}

export const loggedInUserStateReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET USER STATE':
      return action.payload.userState
  }

  return state
}
