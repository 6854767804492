import React from 'react'
import {useSelector} from 'react-redux'
import {utils} from '../helperComponents/Utils'

export default function FeatureToggle(props) {
  const isDialogOpen = useSelector((state) => state.isDialogOpen)
  return (
    <div>
      <label className="fmc-toggle fmc-toggle--skeuomorphic">
        <input
          aria-label={props.ariaLabel}
          aria-hidden={isDialogOpen}
          tabIndex={utils.getTabIndex(isDialogOpen)}
          type="checkbox"
          name="toggle-button-1"
          checked={props.value}
          onChange={props.onToggle}
        />
        <span className="fmc-toggle__track" />
      </label>
    </div>
  )
}
