const initialState = {
  loadingFlag: false,
  refreshingInProgress: false,
  apiLoadingInProgress: false,
}

export const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_COMPLETE':
      return {...state, loadingFlag: action.payload.loadingFlag}

    case 'START_REFRESHING':
      return {
        ...state,
        loadingFlag: true,
        refreshingInProgress: true,
      }

    case 'FINISH_REFRESHING':
      return {
        ...state,
        loadingFlag: false,
        refreshingInProgress: false,
      }
    case 'START_API_REFRESHING':
      return {
        ...state,
        apiLoadingInProgress: true,
      }
    case 'FINISH_API_REFRESHING':
      return {
        ...state,
        apiLoadingInProgress: false,
      }
  }

  return state
}
