import React, {useEffect, useRef} from 'react'
import {APP_CONSTANT} from '../helperComponents/Constants'
import SVGIcon from '../SVGIcon'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

export default function InternetBanner() {
  const {t} = useTranslation()
  const internetFlag = useSelector((state) => state.isConnected)

  const pageTitle = useRef(null)
  useEffect(() => {
    if (pageTitle.current !== null) pageTitle.current.focus()
  }, [])

  const refreshPage = () => {
    window.location.reload()
  }

  function renderRefreshIconByBrand() {
    return APP_CONSTANT.CONFIG.BRAND_CODE === 'F' ? (
      <SVGIcon name="error_refresh" className="close-icon" width="16px" height="16px" />
    ) : (
      <SVGIcon name="lincoln_refresh" className="close-icon" width="16px" height="16px" />
    )
  }

  return (
    <div
      className={`error-banner fmc-top-banner fmc-top-banner--error`}
      data-testid="internet-banner"
      hidden={!internetFlag}
    >
      <div className="fmc-top-banner__inner">
        <span className="fmc-top-banner__content">{t('lbl_internet_connection')}</span>

        <button
          className="fmc-top-banner__dismiss error-banner__refresh-button"
          aria-label="close"
          aria-hidden={String(!internetFlag)}
          onClick={refreshPage}
        >
          {renderRefreshIconByBrand()}
        </button>
      </div>
    </div>
  )
}
