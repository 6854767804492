import React from 'react'
import Dialog from './Dialog'
import {useTranslation} from 'react-i18next'
import {FEATURE_UI_NAME, APP_CONSTANT} from '../helperComponents/Constants'

export default function UnlinkVehicleDialog(props) {
  const {t} = useTranslation()

  function getUnlinkPara1Text() {
    return t('lbl_unlink_vehicle_dialog_para1').replaceAll('%variablefield%', props.featureName)
  }

  function getUnlinkPara2Text() {
    return t('lbl_unlink_vehicle_dialog_para2').replaceAll('%variablefield%', props.featureName)
  }

  function renderVehicleInfo() {
    if (props.vehicleName) {
      return (
        <>
          <span className="unlink-vehicle-dialog__section-title">{t('lbl_your_vehicle')}</span>
          <p className="unlink-vehicle-dialog__vehicle-name">{props.vehicleName}</p>
        </>
      )
    }
  }

  const alexaParagraphs = t('lbl_unlink_vehicle_dialog_amazon_alexa').split(':')

  function replaceAppName(paragraph) {
    const brand = APP_CONSTANT.CONFIG.BRAND_CODE === 'F' ? 'FordPass' : 'Lincoln Way'
    return paragraph.replaceAll('%appName%', brand)
  }

  function renderContent() {
    if (props.featureName === FEATURE_UI_NAME.amazonAlexa)
      return (
        <>
          <p className="unlink-vehicle-dialog__paragraph">{getUnlinkPara1Text()}</p>
          <p className="unlink-vehicle-dialog__paragraph">{getUnlinkPara2Text()}</p>
          {alexaParagraphs.map((paragraph, index) => (
            <p key={`unlink-vehicle-dialog-p-${index}`} className="unlink-vehicle-dialog__paragraph">
              {replaceAppName(paragraph)}
            </p>
          ))}
        </>
      )

    return (
      <>
        <p className="unlink-vehicle-dialog__paragraph">{getUnlinkPara1Text()}</p>
        <p className="unlink-vehicle-dialog__paragraph">{getUnlinkPara2Text()}</p>
      </>
    )
  }

  return (
    <Dialog
      className="unlink-vehicle-dialog"
      title={t('lbl_unlink_vehicle')}
      subtitle={t(`lbl_${props.featureName}`)}
      open={props.open}
      primaryButtonLabel={t('lbl_unlink')}
      onPrimaryButtonCliked={props.onPrimaryButtonCliked}
      secondaryButtonLabel={t('lbl_cancel')}
      onSecondaryButtonCliked={props.onSecondaryButtonCliked}
      onCloseButtonCliked={props.onCloseButtonCliked}
    >
      {renderVehicleInfo()}
      {renderContent()}
    </Dialog>
  )
}
