import {apiRequest} from '../../actions/ApiActions'
import {APP_CONSTANT} from '../../../components/helperComponents/Constants'
import {utils} from '../../../components/helperComponents/Utils'
import {errorFlag} from '../../actions/ErrorActions'
import {vehicleCountCheck} from '../../actions/VehicleActions'
import {BANNER_FEATURE, BANNER_TYPE} from '../../../components/error/ErrorBanner'
import i18n from 'i18next'

function getAlexaRemoteLoginHeaders() {
  return {
    'Auth-Token': APP_CONSTANT.CONFIG.CAT_AUTH_TOKEN,
    'Application-Id': APP_CONSTANT.CONFIG.APPLICATION_ID,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Headers': '*',
  }
}

export const callAlexaRemoteLoginMiddleware = (store) => (next) => (action) => {
  next(action)
  switch (action.type) {
    case 'CALL ALEXA REMOTE LOGIN':
      store.dispatch({type: `[SPINNER] START`})
      next(
        apiRequest({
          body: action.payload,
          method: 'POST',
          url:
            process.env.NODE_ENV === 'production'
              ? `${APP_CONSTANT.CONFIG.APIGEE_URL}/api/alexaRemoteLogin/getAlexaDelinkDetails`
              : `https://api.stg01e.gcp.ford.com/api/alexaRemoteLogin/getAlexaDelinkDetails`,
          feature: '[ALEXA REMOTE LOGIN]',
          headers: getAlexaRemoteLoginHeaders(),
          responseType: 'json',
        }),
      )
      break

    case '[ALEXA REMOTE LOGIN] API_SUCCESS':
      if (utils.errors.includes(action.payload.data.url)) {
        store.dispatch(
          errorFlag({
            state: BANNER_TYPE.INFO,
            errorStatus: true,
            message: i18n.t('lbl_already_unlinked'),
            feature: BANNER_FEATURE.REFRESH,
          }),
        )
      } else {
        store.dispatch({type: `[SPINNER] STOP`})
        store.dispatch(vehicleCountCheck({loadingFlag: true}))
        const url = action.payload.data.url

        window.open(url, '_blank')

        store.dispatch({type: `PULL TO REFRESH FLAG`, payload: true})
        setTimeout(() => {
          store.dispatch(
            errorFlag({
              state: BANNER_TYPE.INFO,
              errorStatus: true,
              message: i18n.t('lbl_refresh_upto_date_info'),
              feature: BANNER_FEATURE.REFRESH,
            }),
          )
        }, 200)
      }
      store.dispatch({type: `[SPINNER] STOP`})
      store.dispatch(vehicleCountCheck({loadingFlag: true}))
      break

    case '[ALEXA REMOTE LOGIN] API_ERROR':
      console.log(`Error from amazon alexa API for source ${action.type}`)

      next(
        errorFlag({
          state: BANNER_TYPE.ERROR,
          errorStatus: true,
          message: i18n.t('lbl_alexa_generic_error'),
          feature: BANNER_FEATURE.CLOSE_BANNER,
        }),
      )
      store.dispatch({type: `[SPINNER] STOP`})
      store.dispatch(vehicleCountCheck({loadingFlag: true}))
      break
  }
}
