import {useTranslation} from 'react-i18next'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {APP_CONSTANT} from '../helperComponents/Constants'
import {AMPLITUDE_SCREEN_NAME, AMPLITUDE_SECTION_NAME} from '../helperComponents/Analytics/AnalyticsConstants'
import {analytics} from '../helperComponents/Analytics/Analytics'
import {useBrandedTranslation} from '../helperComponents/contentUtils'

export default function ManageDataWithFordTile() {
  const {t} = useTranslation()
  const navigate = useNavigate()

  function handlePreferencesClick() {
    analytics.fireSetPreferencesTappedEvent(
      AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE,
      AMPLITUDE_SECTION_NAME.MANAGE_DATA_WITH_FORD,
    )
    navigate('/manage-data-with-ford')
  }

  function handleMobileAppCookiesClick() {
    analytics.fireMobileAppCookiesTappedEvent(AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE)
    navigate('/mobile-app-cookies')
  }

  const ariaLabelSetPreferences = useBrandedTranslation('lbl_manage_data') + t('lbl_set_preferences')
  const ariaLabelMobileAppCookies = useBrandedTranslation('lbl_manage_data') + t('lbl_Mobile App Cookies')

  return (
    <div className="manage-data-tile" data-testid="manage-data-with-ford-tile">
      <div className="manage-data-tile__image manage-data-tile__image--ford" />
      <div className="manage-data-tile__content">
        <div className="manage-data-tile__content__header">{useBrandedTranslation('lbl_manage_data')}</div>
        <div className="manage-data-tile__content__desc">{t('lbl_policy_info_ford')}</div>
        <div className="manage-data-tile__content__button-wrapper">
          <button
            aria-label={ariaLabelSetPreferences}
            className="fmc-button fmc-button-addon"
            onClick={handlePreferencesClick}
          >
            <span>{t('lbl_set_preferences')}</span>
          </button>
        </div>
        {APP_CONSTANT.CONFIG.REGION === 'EU' && (
          <div className="manage-data-tile__content__button-wrapper">
            <button
              aria-label={ariaLabelMobileAppCookies}
              className="fmc-button fmc-button--outlined fmc-button-addon"
              onClick={handleMobileAppCookiesClick}
            >
              <span>{t('lbl_Mobile App Cookies')}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
