import React from 'react'

const getViewBox = (name) => {
  switch (name) {
    case 'error_icon':
      return '0 0 22 20'

    case 'error_refresh':
      return '0 0 16 16'

    case 'error_close':
      return '0 0 16 16'

    case 'success_icon':
      return '0 0 22 22'

    case 'lincoln_refresh':
      return '0 0 16 16'

    case 'info_icon':
      return '0 0 22 22'

    default:
      return '0 0 32 32'
  }
}

function getErrorIcon() {
  return (
    <g id="F194171_Mobile_Enhanced" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Something-is-not-right" transform="translate(-31.000000, -82.000000)" fill="#D62D14">
        <g id="Banners-/-xBannerVariation-/-Error" transform="translate(0.000000, 65.000000)">
          <g id="Content">
            <g
              id="Banners-/-Component-/-Icon-/-24-/-WarningAlert-Secondary"
              transform="translate(30.000000, 15.000000)"
            >
              <path
                d="M12,16.7948847 C11.2879686,16.7948847 10.7779669,17.2616858 10.7779669,17.952312 C10.7779669,18.6441351 11.2879686,19.1252992 12,19.1252992 C12.7107995,19.1252992 13.2220331,18.6441351 13.2220331,17.952312 C13.2220331,17.2616858 12.7107995,16.7948847 12,16.7948847 Z M12.5260163,15.5632481 C12.6652196,14.1197556 12.9276118,10.1663097 12.9276118,9.04000252 L12.9276118,8.43794885 L11.1019535,8.43794885 L11.1019535,9.04000252 C11.1019535,10.1663097 11.3655776,14.1197556 11.5047809,15.5632481 L12.5260163,15.5632481 Z M12,2.5 C11.5614478,2.5 11.1561566,2.72861283 10.9393442,3.0984629 L1.16061561,19.7237621 C0.945035168,20.0912183 0.946267056,20.5424594 1.16554316,20.9087187 C1.38481926,21.274978 1.78641482,21.5 2.22250325,21.5 L21.7774968,21.5 C22.2135852,21.5 22.6151807,21.274978 22.8344568,20.9087187 C23.0537329,20.5424594 23.0549648,20.0912183 22.8393844,19.7237621 L13.0606558,3.0984629 C12.8438434,2.72861283 12.4385522,2.5 12,2.5 Z"
                id="Warning"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  )
}

function getErrorRefresh() {
  return (
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1.3" transform="translate(-334.000000, -79.000000)" fill="#102B4E" fillRule="nonzero">
        <g id="Refresh" transform="translate(334.000000, 79.000000)">
          <path d="M8.0007488,0 C12.41969,0 16,3.5824334 16,8 C16,10.2093593 15.0991627,12.2033117 13.6499895,13.6489561 L13.6499895,13.6489561 L14.8584018,14.8573074 L10.5120345,16 L11.6363532,11.6342692 L12.8459174,12.8437725 C14.0877366,11.6043196 14.8584018,9.89373651 14.8584018,8 C14.8584018,4.212527 11.7872607,1.14269258 8.0007488,1.14269258 C7.40979025,1.14269258 6.84071913,1.22562995 6.29353531,1.36616271 L6.29353531,1.36616271 L6.60687004,0.12901368 C7.05959265,0.0495320364 7.52383491,0 8.0007488,0 Z M5.46031838,0.000691141818 L4.36364684,4.34338373 L3.1656023,3.14539957 C1.91802323,4.38600432 1.14275017,6.10119511 1.14275017,7.99953927 C1.14275017,11.7870122 4.2127393,14.8568466 8.0004032,14.8568466 C8.58214596,14.8568466 9.14315338,14.777365 9.68227338,14.640288 L9.68227338,14.640288 L9.36548276,15.8751332 C8.92082393,15.952311 8.46579738,15.9995393 8.0004032,15.9995393 C3.58146199,15.9995393 0,12.4182577 0,7.99953927 C0,5.78557236 0.9054452,3.78816415 2.36037813,2.34136789 L2.36037813,2.34136789 L1.14275017,1.12495321 L5.46031838,0.000691141818 Z" />
        </g>
      </g>
    </g>
  )
}

const getPath = (name) => {
  switch (name) {
    case 'error_icon':
      return getErrorIcon()

    case 'error_refresh':
      return getErrorRefresh()

    case 'error_close':
      return (
        <g id="Lincoln-Way-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Error-5" transform="translate(-329.000000, -78.000000)" fill="#000000" fillRule="nonzero">
            <g id="close" transform="translate(329.000000, 78.000000)">
              <path
                d="M8.57142857,8.036 L15.4645714,1.10742857 C15.6074286,0.964571429 15.6074286,0.750285714 15.4645714,0.607428571 C15.3217143,0.428571429 15.0714286,0.428571429 14.9285714,0.607428571 L8.03542857,7.536 L1.03542857,0.536 C0.892571429,0.393142857 0.642285714,0.393142857 0.499428571,0.536 C0.356571429,0.678857143 0.356571429,0.929142857 0.499428571,1.072 L7.53542857,8.03657143 L0.642285714,14.9651429 C0.499428571,15.108 0.499428571,15.3582857 0.642285714,15.5011429 C0.678285714,15.5725714 0.785142857,15.6085714 0.892571429,15.6085714 C0.964,15.6085714 1.07142857,15.5725714 1.10685714,15.5011429 L8.03542857,8.57257143 L14.8925714,15.3942857 C14.9285714,15.4657143 15.0354286,15.5017143 15.1068571,15.5017143 C15.2142857,15.5017143 15.3211429,15.4657143 15.3571429,15.3942857 C15.5,15.2514286 15.5,15.0371429 15.3571429,14.8942857 L8.57142857,8.036 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      )

    case 'success_icon':
      return (
        <g id="F194171_Mobile_Enhanced" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="9.SL_Success_Message" transform="translate(-31.000000, -81.000000)" fill="#008200">
            <g id="Banners-/-xBannerVariation-/-Success" transform="translate(0.000000, 65.000000)">
              <g id="Content">
                <g
                  id="Banners-/-Component-/-Icon-/-24-/-Checkmark-Secondary"
                  transform="translate(30.000000, 15.000000)"
                >
                  <path
                    d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M16.901875,6.5 L9.804125,13.5710416 L7.094,10.8691209 L5.125,12.8342773 L9.804125,17.5 L18.875,8.46244767 L16.901875,6.5 Z"
                    id="Checkmark"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      )
    case 'info_icon':
      return (
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="Banners-/-Component-/-Variation-/-Default"
            transform="translate(-25.000000, -17.000000)"
            fill="#102B4E"
          >
            <g id="Content">
              <g
                id="Banners-/-Component-/-Icon-/-24-/-Info-Secondary"
                transform="translate(24.000000, 16.000000)"
              >
                <path
                  d="M23,12 C23,18.0741541 18.0753834,23 12.000792,23 C5.9246166,23 1,18.0741541 1,12 C1,5.92584593 5.9246166,1 12.000792,1 C18.0753834,1 23,5.92584593 23,12 Z M12.000792,8.07199424 C12.6518108,8.07199424 13.1792786,7.54298056 13.1792786,6.89200864 C13.1792786,6.24103672 12.6518108,5.71360691 12.000792,5.71360691 C11.3497732,5.71360691 10.8223054,6.24103672 10.8223054,6.89200864 C10.8223054,7.54298056 11.3497732,8.07199424 12.000792,8.07199424 Z M12.7864497,15.9280058 L14.3577651,15.9280058 L14.3577651,17.4992081 L9.64381885,17.4992081 L9.64381885,15.9280058 L11.2151343,15.9280058 L11.2151343,11.2143988 L9.64381885,11.2143988 L9.64381885,9.64319654 L12.7864497,9.64319654 L12.7864497,15.9280058 Z"
                  id="Info"
                />
              </g>
            </g>
          </g>
        </g>
      )

    case 'lincoln_refresh':
      return (
        <g id="Lincoln-Way-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Error-4" transform="translate(-329.000000, -78.000000)" fill="#ecedee" fillRule="nonzero">
            <g id="linc1638-lw-icons-38px-c16-expanded-19" transform="translate(329.000000, 78.000000)">
              <path
                d="M13.9093333,2.96969697 C12.6666667,1.33333333 10.7878788,0.333090909 8.72727273,0.0906666667 C8.57551515,0.0300606061 8.42424242,0.151272727 8.42424242,0.363636364 C8.42424242,0.545454545 8.48484848,0.697212121 8.69721212,0.697212121 C10.576,0.909575758 12.3035152,1.87927273 13.4850909,3.33381818 C14.6671515,4.8489697 15.1820606,6.69769697 14.9396364,8.60654545 C14.7272727,10.4853333 13.7575758,12.2128485 12.3030303,13.3944242 C10.7878788,14.5764848 8.93915152,15.1219394 7.03030303,14.8489697 C5.15151515,14.6671515 3.424,13.6669091 2.24242424,12.2123636 C1.06036364,10.7272727 0.545454545,8.84848485 0.727272727,6.93963636 C0.939636364,5.18206061 1.78812121,3.60630303 3.09090909,2.45478788 L3.09090909,4.81842424 C3.09090909,4.97018182 3.21212121,5.09139394 3.42448485,5.09139394 C3.60630303,5.09139394 3.72751515,4.97018182 3.72751515,4.78836364 L3.72751515,1.75806061 C3.72751515,1.60630303 3.60630303,1.48509091 3.39393939,1.48509091 L0.39369697,1.51563636 C0.241939394,1.51563636 0.0906666667,1.63684848 0.0906666667,1.78860606 C0.0906666667,1.97042424 0.242424242,2.12218182 0.39369697,2.12218182 L2.57551515,2.12218182 C1.21163636,3.39490909 0.363151515,5.06181818 0.120727273,6.91006061 C-0.12169697,8.94060606 0.45430303,10.9706667 1.75709091,12.6070303 C3.02981818,14.2433939 4.90860606,15.273697 6.93915152,15.5161212 C7.27272727,15.5466667 7.54521212,15.5466667 7.87878788,15.5466667 C9.60630303,15.5466667 11.2727273,14.9706667 12.6666667,13.8797576 C14.3030303,12.6070303 15.3032727,10.7282424 15.545697,8.69769697 C15.7881212,6.66715152 15.2426667,4.60654545 13.9093333,2.97018182 L13.9093333,2.96969697 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      )
    default:
      return <path />
  }
}

const SVGIcon = ({name = '', style = {}, viewBox = '', width = '100%', className = '', height = '100%'}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getPath(name)}
  </svg>
)

export default SVGIcon
