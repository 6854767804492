export const privaciesReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET PRIVACY DETAILS':
      return [
        ...action.payload.thirdPartyPrivacyData,
        ...action.payload.macPrivacyData,
        ...action.payload.fordPrivacyData,
      ]
  }

  return state
}

export const privacyEnableReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET PRIVACY ENABLE':
      return action.payload.isPrivacyEnabled
  }

  return state
}
