import React from 'react'
import {useSelector} from 'react-redux'
import {utils} from '../helperComponents/Utils'

export default function HyperLink({text, onClick, redirectUrl}) {
  const isDialogOpen = useSelector((state) => state.isDialogOpen)
  return (
    <a
      className={'mac-page__hyper-link'}
      rel="noopener noreferrer"
      target={'_blank'}
      onClick={onClick}
      href={redirectUrl}
      aria-hidden={isDialogOpen}
      tabIndex={utils.getTabIndex(isDialogOpen)}
    >
      {text}
    </a>
  )
}
