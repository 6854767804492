export function createFeaturesByVinMap(featuresByVinArrays) {
  const map = new Map()
  featuresByVinArrays.forEach((arr) => {
    const features = addInfoTexts(arr.applications)
    map.set(arr.key, features)
  })
  return map
}

export function addInfoTexts(features) {
  return features.map((feature) => {
    const updatedFeature = {
      ...feature,
      infoText: `lbl_${feature.uiName}_para1`,
      infoTextF: `lbl_${feature.uiName}_para1_F`,
      infoTextL: `lbl_${feature.uiName}_para1_L`,
    }
    return updatedFeature
  })
}
