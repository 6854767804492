import React from 'react'
import {useTranslation} from 'react-i18next'

export default function Dialog(props) {
  const open = props.open
  const {t} = useTranslation()
  function renderActionButtons() {
    const primaryButton = (
      <button
        className="dialog__content__buttons-wrapper__button dialog__content__buttons-wrapper__button--primary fmc-button"
        onClick={props.onPrimaryButtonCliked}
        aria-label={props.subtitle + props.primaryButtonLabel}
      >
        {props.primaryButtonLabel}
      </button>
    )
    const secondaryButton = (
      <button
        className="dialog__content__buttons-wrapper__button dialog__content__buttons-wrapper__button--secondary fmc-button fmc-button--outlined"
        onClick={props.onSecondaryButtonCliked}
        aria-label={props.subtitle + props.secondaryButtonLabel}
      >
        {props.secondaryButtonLabel}
      </button>
    )

    if (props.onPrimaryButtonCliked && props.onSecondaryButtonCliked)
      return (
        <>
          {primaryButton}
          {secondaryButton}
        </>
      )
    if (props.onPrimaryButtonCliked) return primaryButton
    if (props.onSecondaryButtonCliked) return secondaryButton
  }

  function generateClassName() {
    let className = 'dialog fmc-dialog fds-align--center'
    if (props.className) className += ` ${props.className}`
    if (open) className += ' fmc-dialog--opened'
    return className
  }

  return (
    <div className={generateClassName()} role="dialog" aria-modal="true" aria-label={props.label}>
      <div className="dialog__content">
        <div>
          <button
            className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
            aria-label={t('lbl_cancel')}
            onClick={props.onCloseButtonCliked}
          />

          <h3 className="dialog__content__title fmc-type--heading5">{props.title}</h3>
          <h4 className="dialog__content__subtitle fmc-type--subtitle1">{props.subtitle}</h4>
          <div className="dialog__content__body fmc-type--body1">{props.children}</div>
        </div>
        <div className="dialog__content__buttons-wrapper">{renderActionButtons()}</div>
      </div>
    </div>
  )
}
