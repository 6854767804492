import React, {useEffect, useState} from 'react'
import ManageData from './ManageData'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {utils} from '../helperComponents/Utils'
import {createFeaturesByVinMap} from '../helperComponents/DataProcessor'
import {setActivePage} from '../../redux/actions/PageLoadActions'
import {analytics} from '../helperComponents/Analytics/Analytics'
import {
  AMPLITUDE_RUNNING_BLOCK_NAME,
  AMPLITUDE_SCREEN_NAME,
} from '../helperComponents/Analytics/AnalyticsConstants'

const ManageDataWithFord = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const authorizedVins = useSelector((state) => state.vins.authorizedVins)
  const manageDataWithFord = useSelector((state) => state.manageDataWithFord)
  const isDialogOpen = useSelector((state) => state.isDialogOpen)
  const featuresByVin = createFeaturesByVinMap(manageDataWithFord)
  const [vehicleData, setVehicleData] = useState(getVehicleData())
  const activePage = useLocation().pathname

  useEffect(() => {
    dispatch(setActivePage(activePage))
  }, [])

  useEffect(() => {
    setVehicleData(getVehicleData())
  }, [manageDataWithFord])

  function getVehicleData() {
    const data = utils.formatVehicleDataByProvider(authorizedVins, manageDataWithFord)
    return utils.getOrderedVehicleData(data)
  }

  function handleGoBackClicked() {
    analytics.fireGoBackTappedEvent(
      AMPLITUDE_SCREEN_NAME.MANAGE_DATA_WITH_FORD,
      AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE,
      AMPLITUDE_RUNNING_BLOCK_NAME.FOOTER,
    )
    navigate(-1)
  }

  return (
    <div className={'manage-data'}>
      <ManageData featuresByVin={featuresByVin} vehicleData={vehicleData} />
      <div className={'manage-data__go-back-button-wrapper'}>
        <button
          className="fmc-button"
          aria-hidden={isDialogOpen}
          tabIndex={utils.getTabIndex(isDialogOpen)}
          onClick={handleGoBackClicked}
        >
          {t('lbl_go_back')}
        </button>
      </div>
    </div>
  )
}
export default ManageDataWithFord
