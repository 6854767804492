import {useTranslation} from 'react-i18next'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {analytics} from '../helperComponents/Analytics/Analytics'
import {AMPLITUDE_SCREEN_NAME} from '../helperComponents/Analytics/AnalyticsConstants'
import {useSelector} from 'react-redux'
import {utils} from '../helperComponents/Utils'
import {useBrandedTranslation} from '../helperComponents/contentUtils'

const UnlinkThirdPartyAccounts = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const isDialogOpen = useSelector((state) => state.isDialogOpen)
  const initialLoad = true
  function generateUnlinkFeatures(application) {
    function openFeature() {
      analytics.fireUnlinkThirdPartiesTappedEvent(
        AMPLITUDE_SCREEN_NAME.MANAGE_DATA_WITH_THIRD_PARTIES,
        application.uiName,
      )
      navigate('/manage-data-with-third-parties/unlink', {state: {application, initialLoad}})
    }

    return (
      <div key={application.uiName}>
        <button
          aria-hidden={isDialogOpen}
          tabIndex={utils.getTabIndex(isDialogOpen)}
          aria-label={t('lbl_unlink_third_party_accounts') + application.uiName}
          className="unlink-third-party-tile__feature-button-wrapper"
          onClick={openFeature}
        >
          <span className="unlink-third-party-tile__button-text-wrapper">{application.uiName}</span>
          <span className="fds-icon fds-font--ford-icons__chevron-right fds-align--right unlink-third-party-tile__chevron-wrapper" />
        </button>
      </div>
    )
  }

  return (
    <div>
      <div className="unlink-third-party-tile">
        <div className={'unlink-third-party-tile__header'}>
          <div className={'unlink-third-party-tile__header__title'}>
            {t('lbl_unlink_third_party_accounts')}
          </div>
        </div>
        <div className={'unlink-third-party-tile__description'}>
          {useBrandedTranslation('lbl_unlink_third_party_accounts_desc')}
        </div>
        <div className={'unlink-third-party-tile__feature-wrapper'}>
          <div>{props.unlinkApplications.map((application) => generateUnlinkFeatures(application))}</div>
        </div>
      </div>
    </div>
  )
}

export default UnlinkThirdPartyAccounts
