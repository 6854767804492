export const CONSENT_DATA = '[CONSENT_DATA]'
export const PRIVACY_DATA = '[PRIVACY_DATA]'

export const normalizeConsentDataFromAggregatorResponse = ({data, normalize}) => ({
  type: `${CONSENT_DATA} NORMALIZE`,
  payload: {
    data,
    normalizer: normalize,
  },
})

export const normalizePrivacyDataFromAggregatorResponse = ({data}) => ({
  type: `${PRIVACY_DATA} NORMALIZE`,
  payload: {
    data,
  },
})
