import {fmaLoginSuccess} from '../../actions/FmaActions'
import {validateToken} from '../../actions/TokenActions'
import {APP_CONSTANT} from '../../../components/helperComponents/Constants'
import {errorFlag} from '../../actions/ErrorActions'
import {utils} from '../../../components/helperComponents/Utils'
import i18n from 'i18next'
import {BANNER_FEATURE, BANNER_TYPE} from '../../../components/error/ErrorBanner'

export const fmaMiddleware = (store) => (next) => (action) => {
  next(action)

  function dispatchGenericErrorAction() {
    store.dispatch(
      errorFlag({
        state: BANNER_TYPE.ERROR,
        errorStatus: true,
        message: APP_CONSTANT.CONFIG.IS_OWNER
          ? i18n.t('lbl_generic_error_web_part1')
          : i18n.t(`lbl_generic_error_${APP_CONSTANT.CONFIG.BRAND_CODE}`),
        feature: BANNER_FEATURE.GENERIC,
      }),
    )
  }

  switch (action.type) {
    case '[FMA] START_LOGIN':
      if (!APP_CONSTANT.CONFIG.CLIENT_ID) {
        dispatchGenericErrorAction()
        break
      }

      document.body.addEventListener('fma_authenticated', () => store.dispatch(fmaLoginSuccess()))
      document.body.addEventListener('fma_unauthenticated', () => {
        window.fma.login()
      })
      utils.addFmaScript()
      break

    case '[FMA] LOGIN_SUCCESSFULL':
      try {
        APP_CONSTANT.CONFIG.CAT_AUTH_TOKEN = window.fma.CATBundle.cat1_token

        store.dispatch(validateToken())
      } catch (error) {
        console.log(
          `Error after fma login successfull or while setting the cat 1 token for source ${
            action.type
          } and error stack trace: ${error.toString()}`,
        )
        dispatchGenericErrorAction()
      }
      break
  }
}
