const fma = '[FMA]'

export const fmaLoginAction = () => ({
  type: `${fma} START_LOGIN`,
  payload: 'FMA Login payload',
})

export const fmaLoginSuccess = () => ({
  type: `${fma} LOGIN_SUCCESSFULL`,
  payload: 'FMA Login Successfull payload',
})
