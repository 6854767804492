export const setManageDataWithFord = (data) => ({
  type: 'Manage Data With Ford',
  payload: data,
})

export const setManageDataWithThirdParties = (data) => ({
  type: 'Manage Data With Third-Parties',
  payload: data,
})

export const setManageUserDataWithThirdParties = (data) => ({
  type: 'Manage User Data With Third-Parties',
  payload: data,
})

export const setMobileAppCookies = (data) => ({
  type: 'Mobile App Cookies',
  payload: data,
})

export const setUnlinkThirdPartyAccounts = (data) => ({
  type: 'Unlink Third Party Accounts',
  payload: data,
})

export const setSelectedVin = (data) => ({
  type: 'Selected Vin',
  payload: data,
})

export const setSelectedVehicleData = (data) => ({
  type: 'Selected Vehicle Data',
  payload: data,
})
