import React, {useEffect, useState} from 'react'
import ManageData, {MANAGE_DATA_LABEL} from './ManageData'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {utils} from '../helperComponents/Utils'
import {createFeaturesByVinMap} from '../helperComponents/DataProcessor'
import {setActivePage} from '../../redux/actions/PageLoadActions'
import {concat} from 'lodash'
import {
  AMPLITUDE_RUNNING_BLOCK_NAME,
  AMPLITUDE_SCREEN_NAME,
} from '../helperComponents/Analytics/AnalyticsConstants'
import {analytics} from '../helperComponents/Analytics/Analytics'

const ManageDataWithThirdParty = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const authorizedVins = useSelector((state) => state.vins.authorizedVins)
  const manageDataWithThirdParties = useSelector((state) => state.manageDataWithThirdParties)
  const unlinkThirdPartyAccounts = useSelector((state) => state.unlinkThirdPartyAccounts)
  const isDialogOpen = useSelector((state) => state.isDialogOpen)
  const featuresByVin = createFeaturesByVinMap(manageDataWithThirdParties)
  const [vehicleData, setVehicleData] = useState(getVehicleData())
  const manageUserDataWithThirdParties = useSelector((state) => state.manageUserDataWithThirdParties)

  const activePage = useLocation().pathname
  useEffect(() => {
    dispatch(setActivePage(activePage))
  }, [])

  useEffect(() => {
    setVehicleData(getVehicleData())
  }, [manageDataWithThirdParties])

  function getVehicleData() {
    const data = utils.formatVehicleDataByProvider(
      authorizedVins,
      concat(manageDataWithThirdParties, unlinkThirdPartyAccounts),
    )
    return utils.getOrderedVehicleData(data)
  }

  function handleGoBackClicked() {
    analytics.fireGoBackTappedEvent(
      AMPLITUDE_SCREEN_NAME.MANAGE_DATA_WITH_THIRD_PARTIES,
      AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE,
      AMPLITUDE_RUNNING_BLOCK_NAME.FOOTER,
    )
    navigate(-1)
  }

  return (
    <div className={'manage-data'}>
      <ManageData
        featuresByVin={featuresByVin}
        vehicleData={vehicleData}
        unlinkTPAccounts={unlinkThirdPartyAccounts}
        manageUserDataWithThirdParties={manageUserDataWithThirdParties}
        label={MANAGE_DATA_LABEL.THIRD_PARTIES}
      />
      <div className={'manage-data__go-back-button-wrapper'}>
        <button
          aria-hidden={isDialogOpen}
          tabIndex={utils.getTabIndex(isDialogOpen)}
          className="fmc-button"
          onClick={handleGoBackClicked}
        >
          {t('lbl_go_back')}
        </button>
      </div>
    </div>
  )
}
export default ManageDataWithThirdParty
