import DOMPurify from 'dompurify'
import {updateAuthentication} from '../../actions/InitialSetupActions'
import {utils} from '../../../components/helperComponents/Utils'
import {APP_CONSTANT} from '../../../components/helperComponents/Constants'
import {validateToken} from '../../actions/TokenActions'
import {fmaLoginAction} from '../../actions/FmaActions'

export const initialSetupMiddleware = (store) => (next) => (action) => {
  next(action)
  switch (action.type) {
    case '[LOGIN] START':
      utils.initialSetUpValues()
      utils.getBackgroundColor(false)
      utils.setBrandDataSet()

      if (utils.tokenExists()) {
        APP_CONSTANT.CONFIG.FLOW_NAME = 'fragment'
        APP_CONSTANT.CONFIG.CAT_AUTH_TOKEN = DOMPurify.sanitize(utils.getParams('access_token'))
        next(validateToken())
      } else {
        APP_CONSTANT.CONFIG.FLOW_NAME = 'web'
        utils.addOneTrustCookieScript()
        next(fmaLoginAction())
      }
      break
    case '[LOGIN] SUCCESSFULL':
      store.dispatch(updateAuthentication({authStatus: true}))
  }
}
