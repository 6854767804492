import {apiError, apiRequest} from '../../actions/ApiActions'
import {authenticationSuccess} from '../../actions/InitialSetupActions'

import {APP_CONSTANT} from '../../../components/helperComponents/Constants'
import {errorFlag} from '../../actions/ErrorActions'
import {utils} from '../../../components/helperComponents/Utils'
import i18n from 'i18next'
import {BANNER_FEATURE, BANNER_TYPE} from '../../../components/error/ErrorBanner'
import {setLoadingFlag} from '../../actions/LoadingActions'
import {getBrandedTranslation} from '../../../components/helperComponents/contentUtils'

export function getTokenValidateHeaders() {
  return {
    'Application-Id': APP_CONSTANT.CONFIG.APPLICATION_ID,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
}

export const tokenMiddleware = (store) => (next) => (action) => {
  next(action)
  const {apiLoadingInProgress} = store.getState().loading
  switch (action.type) {
    case '[TOKEN VALIDATE] START':
      store.dispatch({type: 'RESET MANAGE YOUR DATA SHARING DETAILS'})
      store.dispatch({type: 'RESET MANAGE PRIVACY SETTINGS DETAILS'})
      store.dispatch({type: 'RESET UNLINK THIRD PARTY ACCOUNTS'})

      if (APP_CONSTANT.CONFIG.IS_OWNER) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
        if (!apiLoadingInProgress) {
          store.dispatch(setLoadingFlag({loadingFlag: false}))
        }
      }

      if (!APP_CONSTANT.CONFIG.PRELOAD_STATUS) {
        // TODO: test if preload needed below
        // store.dispatch({type: `PRE_LOAD_FONTS_IMAGES `})
        utils.initialSetUpValues()
        utils.getFavicon()

        APP_CONSTANT.CONFIG.PRELOAD_STATUS = true
      }
      if (
        utils.errors.includes(APP_CONSTANT.CONFIG.COUNTRY_CODE) ||
        utils.errors.includes(APP_CONSTANT.CONFIG.REGION)
      ) {
        store.dispatch(
          errorFlag({
            state: BANNER_TYPE.ERROR,
            errorStatus: true,
            message: APP_CONSTANT.CONFIG.IS_OWNER
              ? i18n.t('lbl_generic_error_web_part1')
              : getBrandedTranslation('lbl_generic_error'),
            feature: BANNER_FEATURE.GENERIC,
          }),
        )
        next(setLoadingFlag({loadingFlag: true}))
        store.dispatch({type: `[SPINNER] STOP`})
      } else {
        next(
          apiRequest({
            body: {
              'Application-Id': APP_CONSTANT.CONFIG.APPLICATION_ID,
              Token: APP_CONSTANT.CONFIG.CAT_AUTH_TOKEN,
            },
            method: 'POST',
            url:
              process.env.NODE_ENV === 'production'
                ? `${APP_CONSTANT.CONFIG.APIGEE_URL}/api/token-validation/v2`
                : 'https://api.qa01e.gcp.ford.com/api/token-validation/v2',
            feature: '[TOKEN VALIDATE]',
            headers: getTokenValidateHeaders(),
            responseType: 'json',
          }),
        )
      }
      break

    case '[TOKEN VALIDATE] API_SUCCESS':
      try {
        const tokenValidateResponse = action.payload.data
        if (tokenValidateResponse.Status.toLowerCase() === 'valid') {
          store.dispatch(authenticationSuccess())

          store.dispatch({type: 'CALL EXPERIENCE LAYER API'})
        } else {
          store.dispatch(
            apiError({
              response: tokenValidateResponse,
              feature: '[TOKEN VALIDATE]',
            }),
          )
        }
      } catch (error) {
        console.log(
          `Error after token validate api success for source ${
            action.type
          } and error details: ${error.toString()}`,
        )

        store.dispatch(
          errorFlag({
            state: BANNER_TYPE.ERROR,
            errorStatus: true,
            message: APP_CONSTANT.CONFIG.IS_OWNER
              ? i18n.t('lbl_generic_error_web_part1')
              : getBrandedTranslation('lbl_generic_error'),
            feature: BANNER_FEATURE.GENERIC,
          }),
        )
        next(setLoadingFlag({loadingFlag: true}))
        store.dispatch({type: `[SPINNER] STOP`})
      }
      break
    case '[TOKEN VALIDATE] API_ERROR':
      console.log(`Error after token validate api error for source ${action.type}`)

      store.dispatch(
        errorFlag({
          state: BANNER_TYPE.ERROR,
          errorStatus: true,
          message: APP_CONSTANT.CONFIG.IS_OWNER
            ? i18n.t('lbl_generic_error_web_part1')
            : getBrandedTranslation('lbl_generic_error'),
          feature: BANNER_FEATURE.GENERIC,
        }),
      )
      next(setLoadingFlag({loadingFlag: true}))
      store.dispatch({type: `[SPINNER] STOP`})
      break
  }
}
