import {useEffect, useState} from 'react'

export default function useResponsive() {
  const [desktopView, setDesktopView] = useState(window.innerWidth > 1200)

  const updateDimensions = () => {
    const width = window.innerWidth
    setDesktopView(width > 1200)
    return desktopView
  }

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return desktopView
}
