/* eslint-disable no-unused-vars */
const consents = '[CONSENTS]'

export const clearSelectedConsents = () => ({
  type: `${consents} CLEAR_SELECTED_CONSENT`,
  payload: [],
})

export const unlinkConsent = ({applicationId, vin, llId, consentContext, appCountryCode}) => ({
  type: `${consents} UNLINK`,
  payload: {
    consents: {
      consents: [buildConsentObject(consentContext, appCountryCode, llId, vin, '0')],
    },
    applicationId,
  },
})

export const saveConsent = ({applicationId, vin, llId, consentContext, appCountryCode, consentStatus}) => ({
  type: '[MAINTAIN CONSENT]',
  payload: {
    consents: {
      consents: [buildConsentObject(consentContext, appCountryCode, llId, vin, consentStatus)],
    },
    applicationId,
  },
})

function buildConsentObject(consentContext, appCountryCode, llId, vin, consentStatus) {
  return {
    ...(!!vin && {vin}),
    consentContext,
    appCountryCode,
    llId,
    consentStatus,
  }
}
