export const setAuthorizedusersForVin = (authorizedUsersForVin) => ({
  type: `SET AUTHORIZED USERS`,
  payload: {
    authorizedUsersForVin,
  },
})

export const setUserGuid = (userGuid) => ({
  type: `SET USER GUID`,
  payload: {
    userGuid,
  },
})

export const setUserState = (userState) => ({
  type: `SET USER STATE`,
  payload: {
    userState,
  },
})
