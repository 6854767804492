export const setLoadingFlag = ({loadingFlag}) => ({
  type: 'LOADING_COMPLETE',
  payload: {
    loadingFlag,
  },
})

export const startRefreshing = () => ({
  type: 'START_REFRESHING',
})

export const finishRefreshing = () => ({
  type: 'FINISH_REFRESHING',
})

export const startApiRefreshing = () => ({
  type: 'START_API_REFRESHING',
})

export const finishApiRefreshing = () => ({
  type: 'FINISH_API_REFRESHING',
})
