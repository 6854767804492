export const selectedConsentReducer = (state = [], action) => {
  switch (action.type) {
    case '[CONSENTS] UPDATE_SELECTED_CONSENT':
      return [action.payload]

    case '[CONSENTS] CLEAR_SELECTED_CONSENT':
      return []
  }

  return state
}
