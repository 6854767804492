import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {utils} from '../helperComponents/Utils'
import {useSelector} from 'react-redux'
import {analytics} from '../helperComponents/Analytics/Analytics'
import {AMPLITUDE_SCREENNAME_MAP} from '../helperComponents/Analytics/AnalyticsConstants'

const CHARACTER_LIMIT = 200

const ParagraphTruncated = (props) => {
  const {t} = useTranslation()
  const {infoText, featureName} = props
  const [exceedLimit, setExceedLimit] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [displayText, setDisplayText] = useState(infoText)
  const isDialogOpen = useSelector((state) => state.isDialogOpen)
  const activePage = useSelector((state) => state.activePage)
  useEffect(() => {
    setExceedLimit(infoText.length > CHARACTER_LIMIT)
  }, [infoText])

  useEffect(() => {
    if (expanded || !exceedLimit) return setDisplayText(infoText)
    return setDisplayText(`${infoText.substring(0, CHARACTER_LIMIT)}...`)
  }, [expanded, exceedLimit])

  function handleExpandCollapseClicked() {
    fireExpandCollapseAnalyticsEvent()
    updateExpanded()
  }

  function fireExpandCollapseAnalyticsEvent() {
    const screenName = AMPLITUDE_SCREENNAME_MAP.get(activePage)
    expanded
      ? analytics.fireCollapseTappedEvent(screenName, featureName)
      : analytics.fireExpandTappedEvent(screenName, featureName)
  }

  function updateExpanded() {
    setExpanded(!expanded)
  }

  function renderButton() {
    if (exceedLimit) {
      const key = expanded ? 'lbl_collapse' : 'lbl_expand'
      return (
        <button
          aria-hidden={isDialogOpen}
          tabIndex={utils.getTabIndex(isDialogOpen)}
          onClick={handleExpandCollapseClicked}
          className={'fmc-text-button paragraph-truncated__button'}
        >
          {t(key)}
        </button>
      )
    }
  }

  function renderParagraph() {
    return (
      <p className={`paragraph-truncated__content`}>
        {displayText}
        {renderButton()}
      </p>
    )
  }

  return <div className={'paragraph-truncated'}>{renderParagraph()}</div>
}

export default ParagraphTruncated
