const login = '[LOGIN]'
const auth = '[AUTHENTICATION]'

export const startLogin = () => ({
  type: `${login} START`,
  payload: 'Login payload',
})

export const authenticationSuccess = () => ({
  type: `${login} SUCCESSFULL`,
  payload: 'Login payload',
})

export const updateAuthentication = (status) => ({
  type: `UPDATE-${auth}`,
  payload: {
    authStatus: status.authStatus,
  },
})
