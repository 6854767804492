const initialState = {
  state: '',
  status: false,
  message: '',
  feature: '',
}

export const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case '[ERROR] BANNER':
      return {
        state: action.payload.state,
        status: action.payload.errorStatus,
        message: action.payload.message,
        feature: action.payload.feature,
      }
    case '[ERROR] BANNER DISMISS':
      return {
        ...state,
        status: false,
      }
  }
  return state
}

export const internetErrorReducer = (state = false, action) => {
  switch (action.type) {
    case 'INTERNET ERROR':
      return action.payload
  }

  return state
}

export const popupDetailsReducer = (state = false, action) => {
  switch (action.type) {
    case 'SET INFO DETAILS':
      return action.payload
  }

  return state
}
