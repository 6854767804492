export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_ERROR = 'API_ERROR'

export const apiRequest = ({body, method, url, headers, feature, uiName, responseType}) => ({
  type: `${feature} ${API_REQUEST}`,
  payload: body,
  meta: {method, url, headers, feature, uiName, responseType},
})

export const apiSuccess = ({response, feature, uiName}) => ({
  type: `${feature} ${API_SUCCESS}`,
  payload: response,
  meta: {feature},
  uiName,
})

export const apiError = ({error, feature, uiName}) => ({
  type: `${feature} ${API_ERROR}`,
  payload: error,
  meta: {feature},
  uiName,
})
