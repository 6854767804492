import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import ManageDataWithThirdPartiesTile from './ManageDataWithThirdPartiesTile'
import ManageDataWithFordTile from './ManageDataWithFordTile'
import {useDispatch, useSelector} from 'react-redux'
import useResponsive from '../helperComponents/ResponsiveComponent'
import {APPLICATION_PATH, APP_CONSTANT, FEATURE_UI_NAME} from '../helperComponents/Constants'
import {setActivePage} from '../../redux/actions/PageLoadActions'
import {useLocation, useNavigate} from 'react-router'
import MobileAppCookiesButton from './MobileAppCookiesButton'
import {useBrandedTranslation} from '../helperComponents/contentUtils'
import BottomLinks from '../components/BottomLinks'
import {useSearchParams} from 'react-router-dom'

const BYPASSABLE_FEATURE_NAME = new Map([
  [FEATURE_UI_NAME.googleMapsInFordPass, APPLICATION_PATH.MANAGE_DATA_WITH_THIRD_PARTIES],
  [FEATURE_UI_NAME.googleMapsInLincolnWay, APPLICATION_PATH.MANAGE_DATA_WITH_THIRD_PARTIES],
])

export default function ApplicationLanding() {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const activePage = useLocation().pathname
  const desktopView = useResponsive()
  const {t} = useTranslation()
  const manageDataWithFord = useSelector((state) => state.manageDataWithFord)
  const manageDataWithThirdParties = useSelector((state) => state.manageDataWithThirdParties)
  const unlinkThirdPartyAccounts = useSelector((state) => state.unlinkThirdPartyAccounts)
  const manageUserDataWithThirdParties = useSelector((state) => state.manageUserDataWithThirdParties)
  const authorizedVins = useSelector((state) => state.vins.authorizedVins)

  useEffect(() => {
    handleBypassingLandingPage()
    dispatch(setActivePage(activePage))
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  function handleBypassingLandingPage() {
    const featureName = searchParams.get('feature_name')
    if (featureName) {
      removeFeatureNameParamFromURL()

      if (getPageToBypassTo(featureName)) {
        navigate(getPageToBypassTo(featureName))
      }
    }
  }

  function userHasConsentDataForFeatureName(featureName) {
    return manageUserDataWithThirdParties.find((app) => app.uiName === featureName)
  }

  function getPageToBypassTo(featureName) {
    if (
      BYPASSABLE_FEATURE_NAME.get(featureName) === APPLICATION_PATH.MANAGE_DATA_WITH_THIRD_PARTIES &&
      shouldRenderManageDataWithThridParties() &&
      userHasConsentDataForFeatureName(featureName)
    ) {
      return APPLICATION_PATH.MANAGE_DATA_WITH_THIRD_PARTIES
    }
  }

  function removeFeatureNameParamFromURL() {
    searchParams.delete('feature_name')
    setSearchParams(searchParams, {replace: true})
  }

  function shouldRenderManageDataWithFord() {
    return manageDataWithFord?.length > 0
  }

  function hasManageDataWithThirdPartiesData() {
    return manageDataWithThirdParties?.length > 0
  }

  function hasUnlinkThirdPartyAccountsData() {
    return unlinkThirdPartyAccounts?.length > 0
  }

  function hasManageUserDataWithThirdPartiesData() {
    return manageUserDataWithThirdParties?.length > 0
  }

  function hasOnlyGoogleMapsOnOnwerWeb() {
    const hasOnlyUserDataWithThidParties =
      !hasManageDataWithThirdPartiesData() &&
      !hasUnlinkThirdPartyAccountsData() &&
      hasManageUserDataWithThirdPartiesData()

    const hasOnlyGoogleMapsConsent =
      manageUserDataWithThirdParties?.length === 1 &&
      (manageUserDataWithThirdParties[0]?.uiName === FEATURE_UI_NAME.googleMapsInFordPass
      || manageUserDataWithThirdParties[0]?.uiName === FEATURE_UI_NAME.googleMapsInLincolnWay)

    const isOwnerWeb = APP_CONSTANT.CONFIG.IS_OWNER
    return hasOnlyUserDataWithThidParties && hasOnlyGoogleMapsConsent && isOwnerWeb
  }

  function shouldRenderManageDataWithThridParties() {
    const hasAuthroizedVins = authorizedVins?.length > 0
    const hasConsentOrPrivacyData =
      hasManageDataWithThirdPartiesData() ||
      hasUnlinkThirdPartyAccountsData() ||
      hasManageUserDataWithThirdPartiesData()
    return hasConsentOrPrivacyData && hasAuthroizedVins && !hasOnlyGoogleMapsOnOnwerWeb()
  }

  function renderEmptyHomePage() {
    return (
      <>
        <div className="landing-page">
          <div className="landing-page__heading">
            <span>{useBrandedTranslation('lbl_your_privacy_at')}</span>
          </div>
        </div>
        <div className="landing-page__info landing-page__info--no-tile">
          <span>{t('lbl_policy_info')}</span>
          {APP_CONSTANT.CONFIG.REGION === 'EU' && <MobileAppCookiesButton />}
        </div>
      </>
    )
  }

  function renderManageDataTiles() {
    let containerClassName = 'flex flex-center landing-page__info'
    containerClassName += desktopView ? ' flex-row' : ' flex-column'

    return (
      <>
        <div className="landing-page landing-page--left">
          <div className="flex flex-column w-100 landing-page__heading">
            <span>{useBrandedTranslation('lbl_your_privacy_at')}</span>
            <span className="landing-page__heading-info">{t('lbl_policy_info')}</span>
          </div>
        </div>
        <div className={containerClassName} data-testid="landing-page-tiles-container">
          {shouldRenderManageDataWithFord() && <ManageDataWithFordTile />}
          {shouldRenderManageDataWithThridParties() && <ManageDataWithThirdPartiesTile />}
        </div>
      </>
    )
  }

  function renderDivider() {
    if (desktopView)
      return (
        <div
          className="landing-page__divider"
          data-testid="landing-page-divider"
          style={{marginBottom: '25px'}}
        />
      )
    return <div className="landing-page__divider" data-testid="landing-page-divider" />
  }

  return (
    <div data-testid="application-landing">
      <React.Fragment>
        <div role="main">
          <div className="flex flex-column flex-space-between min-h-100vh">
            {!shouldRenderManageDataWithFord() && !shouldRenderManageDataWithThridParties()
              ? renderEmptyHomePage()
              : renderManageDataTiles()}
            <div>
              {renderDivider()}
              <BottomLinks countryCode={APP_CONSTANT.CONFIG.COUNTRY_CODE} />
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}
