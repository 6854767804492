function getAppDetailsByCategory(state, action) {
  if (state !== undefined && state.length > 0) {
    action.payload.forEach((vinValue) => {
      vinValue.forEach((item) => {
        const existingIndex = state.findIndex((entry) => entry.key === item.vin)
        if (existingIndex === -1) {
          const apps = []
          apps.push(item)
          state.push({key: item.vin, applications: apps})
        } else {
          state[existingIndex].applications.push(item)
        }
      })
    })
    return state
  } else {
    const result = []
    action.payload.forEach((value, key) => {
      result.push({
        key,
        applications: action.payload.get(key),
      })
    })
    return result
  }
}

export const manageDataWithFordReducer = (state = [], action) => {
  switch (action.type) {
    case 'RESET MANAGE YOUR DATA SHARING DETAILS':
      return []
    case 'Manage Data With Ford':
      return getAppDetailsByCategory(state, action)
  }
  return state
}

export const manageDataWithThirdPartiesReducer = (state = [], action) => {
  switch (action.type) {
    case 'RESET MANAGE PRIVACY SETTINGS DETAILS':
      return []
    case 'Manage Data With Third-Parties':
      return getAppDetailsByCategory(state, action)
  }
  return state
}

export const manageUserDataWithThirdPartiesReducer = (state = [], action) => {
  switch (action.type) {
    case 'Manage User Data With Third-Parties':
      return [...action.payload]
  }
  return state
}

export const mobileAppCookieReducer = (state = [], action) => {
  switch (action.type) {
    case 'Mobile App Cookies':
      return [...action.payload]
  }
  return state
}

export const UnlinkThirdPartyAccountsReducer = (state = [], action) => {
  switch (action.type) {
    case 'RESET UNLINK THIRD PARTY ACCOUNTS':
      return []

    case 'Unlink Third Party Accounts':
      return getAppDetailsByCategory(state, action)
  }
  return state
}

export const selectedVinReducer = (state = '', action) => {
  switch (action.type) {
    case 'Selected Vin':
      return action.payload
  }
  return state
}

export const selectedVehicleDataReducer = (state = '', action) => {
  switch (action.type) {
    case 'Selected Vehicle Data':
      return action.payload
  }
  return state
}
