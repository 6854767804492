import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import UnlinkVehicleDialog from '../dialog/UnlinkVehicleDialog'
import {utils} from '../helperComponents/Utils'
import {APP_CONSTANT, APPLICATION_PATH, FEATURE_UI_NAME} from '../helperComponents/Constants'
import LearnMoreDialog from '../dialog/LearnMoreDialog'
import {setActivePage} from '../../redux/actions/PageLoadActions'
import {useDispatch, useSelector} from 'react-redux'
import {getAlexaDelinkDetails} from '../../redux/actions/AlexaActions'
import {startApiRefreshing} from '../../redux/actions/LoadingActions'
import {analytics} from '../helperComponents/Analytics/Analytics'
import {
  AMPLITUDE_RUNNING_BLOCK_NAME,
  AMPLITUDE_SCREEN_NAME,
} from '../helperComponents/Analytics/AnalyticsConstants'

const learnMoreDescList = ['Electrify America', 'Amazon Alexa']
const learnMoreButtonList = ['Electrify America', 'Amazon Alexa']
const noUnlinkButtonFeatureList = ['Electrify America']
const UnlinkFeature = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const selectedApplication = location.state.application
  const userGuid = useSelector((state) => state.userGuid)
  const selectedVin = useSelector((state) => state.selectedVin)
  const vehicleData = useSelector((state) => state.selectedVehicleData)
  const {apiLoadingInProgress} = useSelector((state) => state.loading)
  const [initialLoad, setInitialLoad] = useState(location.state.initialLoad)
  const [showUnlinkVehicleDialog, setShowUnlinkVehicleDialog] = useState(false)
  const [showLearnMoreAccordionDialog, setShowLearnMoreAccordionDialog] = useState(false)
  const [vehicleName, setVehicleName] = useState()
  const [unlinkAccountScreenName, setUnlinkAccountScreenName] = useState()
  const [unlinkPopupScreenName, setUnlinkPopupScreenName] = useState()
  const activePage = useLocation().pathname
  useEffect(() => {
    setUnlinkAccountScreenName(analytics.getUnlinkAccountScreenName(selectedApplication.uiName))
    setUnlinkPopupScreenName(analytics.getUnlinkPopupScreenName(selectedApplication.uiName))
    dispatch(setActivePage(activePage))
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!apiLoadingInProgress) {
      setShowUnlinkVehicleDialog(false)
      if (!initialLoad) {
        analytics.fireUnlinkCompletedEvent(unlinkPopupScreenName)
        navigate(APPLICATION_PATH.MANAGE_DATA_WITH_THIRD_PARTIES, {replace: true})
      }
    }
  }, [apiLoadingInProgress])

  useEffect(() => {
    if (selectedVin && vehicleData && vehicleData.length > 0) {
      const selectedVehicle = vehicleData.find((vehicle) => vehicle.vin === selectedVin)
      setVehicleName(selectedVehicle.vehicleName)
    }
  }, [selectedVin, vehicleData])

  function onUnlinkAccountClicked() {
    analytics.fireUnlinkAccountTappedEvent(unlinkAccountScreenName)
    setShowUnlinkVehicleDialog(true)
  }

  function closeUnlinkAccountDialog() {
    analytics.fireCancelTappedEvent(
      unlinkPopupScreenName,
      unlinkAccountScreenName,
      selectedApplication.uiName,
    )
    setShowUnlinkVehicleDialog(false)
  }

  function exitUnlinkAccountDialog() {
    analytics.firePageExitTappedEvent(
      unlinkPopupScreenName,
      unlinkAccountScreenName,
      selectedApplication.uiName,
    )
    setShowUnlinkVehicleDialog(false)
  }

  function handleUnlink() {
    analytics.fireUnlinkTappedEvent(unlinkPopupScreenName)
    if (selectedApplication.uiName === FEATURE_UI_NAME.amazonAlexa) {
      dispatch(
        getAlexaDelinkDetails({
          vin: selectedApplication.vin,
          fcid: userGuid,
        }),
      )
    } else {
      utils.dispatchUnlinkConsent(dispatch, selectedApplication)
    }
    dispatch(startApiRefreshing())
    setInitialLoad(false)
  }

  function onLearnMoreAccordionClicked() {
    analytics.fireLearnMoreTappedEvent(unlinkAccountScreenName, selectedApplication.uiName)
    setShowLearnMoreAccordionDialog(true)
  }

  function closeLearnMoreAccordionDialog() {
    analytics.fireGoBackTappedEvent(
      AMPLITUDE_SCREEN_NAME.LEARN_MORE,
      unlinkAccountScreenName,
      AMPLITUDE_RUNNING_BLOCK_NAME.FOOTER,
      selectedApplication.uiName,
    )
    setShowLearnMoreAccordionDialog(false)
  }

  function exitLearnMoreAccordionDialog() {
    analytics.firePageExitTappedEvent(
      AMPLITUDE_SCREEN_NAME.LEARN_MORE,
      unlinkAccountScreenName,
      selectedApplication.uiName,
    )
    setShowLearnMoreAccordionDialog(false)
  }

  function getTimeStamp() {
    if (selectedApplication.uiName === FEATURE_UI_NAME.amazonAlexa) {
      return selectedApplication.timeStamp
    }
    return selectedApplication.consentTimeStamp
  }

  function handleGoBackClicked() {
    analytics.fireGoBackTappedEvent(
      unlinkAccountScreenName,
      AMPLITUDE_SCREEN_NAME.MANAGE_DATA_WITH_THIRD_PARTIES,
      AMPLITUDE_RUNNING_BLOCK_NAME.HEADER,
    )
    navigate(-1)
  }

  return (
    <div className={'unlink-feature'}>
      <div className="unlink-feature__nav-bar">
        <button className="unlink-feature__go-back-cta" onClick={handleGoBackClicked}>
          <span className="fds-icon fds-icon fds-icon--offset-left fds-font--ford-icons__chevron-left" />
          {t('lbl_go_back')}
        </button>
        <div className="unlink-feature__nav-bar__header">
          <div className="unlink-feature__nav-bar__header__title">{t('lbl_unlink_third_party_accounts')}</div>
        </div>
      </div>

      <div className="unlink-feature__content">
        <div className={'unlink-feature__header'}>
          <div className={`unlink-feature__${selectedApplication.uiImage}`} />

          <div className={'unlink-feature__header__title'}>{t(`lbl_${selectedApplication.uiName}`)}</div>

          <div className={'unlink-feature__header__date-of-consent'}>
            {`${t(`lbl_date_of_consent`)}: `}
            {utils.formatDateForGivenFormat(
              getTimeStamp(),
              APP_CONSTANT.CONFIG.MOMENT_LANGUAGE_CODE.consentFormat,
            )}
          </div>
        </div>
        {learnMoreDescList.includes(selectedApplication.uiName) && (
          <div className={'unlink-feature__learn-more-wrapper'}>
            <span>{t(`lbl_${selectedApplication.uiName}_para1`)}</span>
          </div>
        )}
        {learnMoreDescList.includes(selectedApplication.uiName) && (
          <div className={'unlink-feature__learn-more-wrapper'}>
            <span>{t(`lbl_${selectedApplication.uiName}_para2`)}</span>
          </div>
        )}
        {learnMoreButtonList.includes(selectedApplication.uiName) && (
          <div className={'unlink-feature__learn-more-wrapper'}>
            <button
              className={'fmc-text-button unlink-feature__learn-more-button'}
              onClick={onLearnMoreAccordionClicked}
            >
              {t('lbl_learn_more')}
            </button>
            <>
              {` ${t('lbl_learn_more_extended')}`} {`${t(`lbl_${selectedApplication.uiName}`)}.`}
            </>
          </div>
        )}
        {noUnlinkButtonFeatureList.includes(selectedApplication.uiName) && (
          <div className={'unlink-feature__learn-more-wrapper'}>
            <span>{t(`lbl_${selectedApplication.uiName}_para3`)}</span>
          </div>
        )}
        {!noUnlinkButtonFeatureList.includes(selectedApplication.uiName) && (
          <div className={'unlink-feature__unlink-account-button-wrapper'}>
            <button className="fmc-button" onClick={onUnlinkAccountClicked}>
              {t('lbl_unlink_account')}
            </button>
          </div>
        )}

        {showLearnMoreAccordionDialog && (
          <LearnMoreDialog
            type={'accordion'}
            open={true}
            featureName={t(`lbl_${selectedApplication.uiName}`)}
            learnMoreCategories={selectedApplication.learnMoreCategories}
            onPrimaryButtonCliked={closeLearnMoreAccordionDialog}
            onCloseButtonCliked={exitLearnMoreAccordionDialog}
          />
        )}

        {showUnlinkVehicleDialog && (
          <UnlinkVehicleDialog
            featureName={selectedApplication.uiName}
            vehicleName={vehicleName}
            open={true}
            onPrimaryButtonCliked={handleUnlink}
            onSecondaryButtonCliked={closeUnlinkAccountDialog}
            onCloseButtonCliked={exitUnlinkAccountDialog}
          />
        )}
      </div>
    </div>
  )
}

export default UnlinkFeature
